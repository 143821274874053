import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';

import Image from '../Image';
import Link from '../Link';

const LandingSection = ({
  buttonText,
  subtitle,
  title,
  background,
  language
}) => (
  <section className="relative flex items-center h-screen shadow-xl">
    <div className="absolute top-0 left-0 w-full h-full">
      <Image
        image={background}
        alt="electro car gets recharged"
        className="absolute top-0 left-0 h-full w-full object-cover"
      />
    </div>
    <div className="container relative px-8 flex flex-col items-start mx-auto pt-64">
      <h1 className="landing-heading-sm text-white-100 sm:landing-heading">
        {title}
      </h1>
      <h2 className="landing-subtitle-sm pb-4 text-white-200 sm:landing-subtitle">
        {subtitle}
      </h2>
      <Link language={language} to="#mission">
        <button className="flex space-x-2 items-center bg-primary-200 rounded-full md:px-12 py-2 px-8 text-white-100 focus-outline-none focus:outline-none">
          <p className="cta-text-sm sm:cta-text">{buttonText}</p>
          <ExpandMore />
        </button>
      </Link>
    </div>
  </section>
);

LandingSection.propTypes = {
  buttonText: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired,
  language: PropTypes.string
};

export default LandingSection;
