import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@material-ui/icons';

import { PageRoutes } from '../../data/constants';

import SectionTitleWrapper from '../SectionTitleWrapper';
import Link from '../Link';
import Image from '../Image';

const Section = ({ title, text }) => (
  <div className="space-y-2">
    <h4 className="project-type-heading sm:project-type-heading text-black-200">
      {title}
    </h4>
    <p className="project-type-text sm:project-type-text text-black-100">
      {text}
    </p>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const WeMoveSection = ({
  buttonText,
  industry,
  language,
  marketing,
  science,
  strategy,
  subtitle,
  title,
  cardImage
}) => (
  <SectionTitleWrapper title={title} subtitle={subtitle}>
    <div className="px-4 container mx-auto">
      <div className="lg:container mx-auto p-4 flex bg-white-100 shadow-100 space-y-8 lg:space-y-0 lg:space-x-8 lg:flex-row flex-col max-w-md">
        <Image
          alt="girl touching on transparent display "
          className="flex-1 object-cover lg:w-1/2"
          image={cardImage}
        />
        <div
          className="flex-1 flex flex-col space-y-8 items-start"
          style={{ height: '50%' }}
        >
          <Section {...marketing} />
          <Section {...strategy} />
          <Section {...science} />
          <Section {...industry} />
          <Link
            to={PageRoutes.REFERENCES}
            language={language}
            className="py-2 px-8 lg:px-12 flex items-center justify-between text-white-100 bg-primary-300 rounded-full space-x-2 mx-auto lg:mx-0"
          >
            <p className="pl-4 sm:cta-text cta-text-sm">{buttonText}</p>
            <ChevronRight />
          </Link>
        </div>
      </div>
    </div>
  </SectionTitleWrapper>
);

WeMoveSection.propTypes = {
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  industry: PropTypes.object.isRequired,
  marketing: PropTypes.object.isRequired,
  science: PropTypes.object.isRequired,
  strategy: PropTypes.object.isRequired,
  cardImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default WeMoveSection;
