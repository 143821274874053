import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const ExpectationsSection = ({ title, banner }) => (
  <section className="py-8">
    <div className="container mx-auto sm:px-4">
      <Image
        className="w-full h-full object-cover shadow-100 bg-white-100"
        alt="rising holdings"
        image={banner}
      />
      <div className="px-8">
        <div className="mx-auto max-w-5xl -mt-16 md:-mt-20 z-10 relative">
          <div
            className="absolute bg-primary-200 w-1/2 z-0"
            style={{ top: '-1rem', right: '-1rem', height: '75%' }}
          />
          <div
            className="bg-primary-400 absolute w-1/2"
            style={{ top: '1rem', left: '-1rem', height: '75%' }}
          />
          <div className="bg-white-100 relative py-8 space-y-8 px-4 border-b border-black-100">
            <div className="flex flex-col items-center space-y-2 relative">
              <h2 className="quote-text-sm text-black-100 sm:quote-text text-center">
                {title}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

ExpectationsSection.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default ExpectationsSection;
