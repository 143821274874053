import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';

import IndexTemplate from '../../templates/Index';

const Index = ({ data, pageContext }) => {
  const {
    markdownRemark: { frontmatter }
  } = data;
  const { language } = pageContext;
  const { pageTitle, ...pageContent } = frontmatter;

  return (
    <Layout language={language} pageTitle={pageTitle} activePage="/">
      <IndexTemplate {...{ ...pageContent, language }} />
    </Layout>
  );
};

Index.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        landingSection: PropTypes.object.isRequired,
        missionSection: PropTypes.object.isRequired,
        expectations: PropTypes.object.isRequired,
        whatWeMove: PropTypes.object.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Index;
