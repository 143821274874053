import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleWrapper from '../SectionTitleWrapper';
import Card from '../Card';

const MissionSection = ({ title, subtitle, actions, business, whyWe }) => (
  <div id="mission">
    <SectionTitleWrapper title={title} subtitle={subtitle}>
      <div className="container px-4 flex flex-col space-y-8 mx-auto lg:flex-row lg:space-y-0 lg:justify-between lg:space-x-8 h-full items-center lg:items-stretch">
        <Card {...actions} />
        <Card {...business} />
        <Card {...whyWe} />
      </div>
    </SectionTitleWrapper>
  </div>
);

MissionSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  whyWe: PropTypes.object.isRequired
};

export default MissionSection;
