import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const Card = ({ title, text, bannerImage }) => (
  <div className="p-4 space-y-4 bg-white-100 shadow-100 flex-1 max-w-md sm:space-y-8 flex-grow w-full">
    <div style={{ height: '160px' }} className="overflow-hidden">
      <Image alt={title} image={bannerImage} className="w-full object-cover" />
    </div>
    <div className="flex flex-col space-y-4">
      <h3 className="card-title-sm text-primary-300 sm:card-title">{title}</h3>
      <p className="card-text-sm text-black-100 sm:card-text">{text}</p>
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  bannerImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default Card;
