import React from 'react';
import PropTypes from 'prop-types';

import LandingSection from '../../components/LandingSection';
import MissionSection from '../../components/MissionSection';
import WeMoveSection from '../../components/WeMoveSection';
import ExpectationsSection from '../../components/ExpectationsSection';

const IndexTemplate = ({
  language,
  landingSection,
  missionSection,
  whatWeMove,
  expectations
}) => (
  <>
    <LandingSection
      {...{
        ...landingSection,
        language
      }}
    />
    <MissionSection {...{ ...missionSection }} />
    <ExpectationsSection {...expectations} />
    <WeMoveSection {...{ ...whatWeMove, language }} />
  </>
);

IndexTemplate.propTypes = {
  language: PropTypes.string.isRequired,
  landingSection: PropTypes.object.isRequired,
  missionSection: PropTypes.object.isRequired,
  expectations: PropTypes.object.isRequired,
  whatWeMove: PropTypes.object.isRequired
};

export default IndexTemplate;
