import { graphql } from 'gatsby';

import Index from './template';

export const pageQuery = graphql`
  query IndexTemplate($language: String) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "Index" }, language: { eq: $language } }
    ) {
      frontmatter {
        pageTitle
        landingSection {
          title
          subtitle
          buttonText
          background {
            childImageSharp {
              fluid(maxWidth: 6500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        missionSection {
          title
          subtitle
          actions {
            title
            text
            bannerImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          business {
            title
            text
            bannerImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          whyWe {
            title
            text
            bannerImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        expectations {
          title
          banner {
            childImageSharp {
              fluid(maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        whatWeMove {
          title
          subtitle
          cardImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          marketing {
            title
            text
          }
          strategy {
            title
            text
          }
          science {
            title
            text
          }
          industry {
            title
            text
          }
          buttonText
        }
      }
    }
  }
`;

export default Index;
